import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { IconContext } from "react-icons";
import {BsGithub,BsLinkedin} from "react-icons/bs";
import {SiGmail} from "react-icons/si";
import './contactme.css';

class ContactMe extends Component {
    render() { 
        return (
        <React.Fragment>
        <div className = "containers">

            <a href = 'https://www.linkedin.com/in/eduardo-sosa-86836a230/' target="_blank"  rel="noreferrer">
                <IconContext.Provider value={{size: '2.5em', className :"react-icons1"}}> 
                    <BsLinkedin/>
                </IconContext.Provider>
            </a> 

        
            <a href = 'https://github.com/EduardoSosaG' target="_blank"  rel="noreferrer">
                <IconContext.Provider value={{size: '2.5em', className :"react-icons2"}}> 
                    <BsGithub/>
                </IconContext.Provider>
            </a> 

            <a href = 'https://mail.google.com/mail/?view=cm&source=mailto&to=en.sosag@gmail.com' target="_blank"  rel="noreferrer" >
                <IconContext.Provider value={{size: '2.5em', className :"react-icons3"}}> 
                    <SiGmail/>
                </IconContext.Provider>
            </a> 
         
      </div>
      </React.Fragment>
        )
    }
}

require('react-dom');
window.React2 = require('react');
console.log(window.React1 === window.React2);
 
export default ContactMe;