import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import MainNavBar from '../mainnavbar'
import './aboutme.css'


function AboutMe() {
  return (
    
  <React.Fragment> 
    <MainNavBar/>
    <div className = 'parent'>
        <div className = 'child'>
          <article className='aboutmetxt'>
            <p className = 'section'>About Me</p>
            <hr className = "title-line"></hr>
            <p className = "p"> Hello, I am Eduardo. I was born and raised in New York City and lived for a few years in the state of Guerrero, Mexico. 
              I am currently a Mathematics and Computer Science senior at Colby College in Waterville, Maine where I focus on coding projects 
              and mathematical research with a concentration in algorithms and computational complexity theory. When I am not coding or doing mathematics research, I am either spending time
              with my loved ones, running, or trying out local restaurants. </p>

            <p className = "p"> With that said, I have a knack for problem-solving, whether it be proving a theorem rigorously or fixing a bug. I have experience in software engineering and industry research.
            I hope you have the opportunity to look at my portfolio or my CV and get to know what I do a little better. 
            Currently, I am looking for software engineering positions in either New York City or the Boston area,
             where I can make a mark and do what I'm talented at. If I sound like a good fit, please do not hesitate in contacting me!</p>

          </article>
        </div>
        <div className = 'child'>
          <img id = 'profile' src = {require('./profile.jpg')} alt ="" />
        </div>
    </div>
  </React.Fragment>
  );
}

export default AboutMe;