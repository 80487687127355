import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home_page/home'
import Research from './components/research_page/research'
import AboutMe from './components/aboutme_page/aboutme'
import Portfolio from './components/portfolio_page/portfolio'

const Main = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home />}></Route>
      <Route exact path='/Research' element={<Research />}></Route>
      <Route exact path='/AboutMe' element={<AboutMe />}></Route>
      <Route exact path='/Portfolio' element={<Portfolio />}></Route>

    </Routes>
  );
}

export default Main;