import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './navbar.css';
import { Link } from "react-router-dom";
import Pdf from './EduardoSosaResume.pdf';

class NavBar extends Component {
    render() { 
        return (
            <nav id = 'navbar' className="navbar navbar-dark navbar-expand-lg bg-navbar">
            <ul className="navbar-nav">
                <li className="nav-item">
                <Link className="nav-link" to="/AboutMe">About Me</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link" href = {Pdf} target = "_blank" rel="noreferrer">CV</a>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="/Portfolio">Portfolio</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="/Research">Research</Link>
                </li>
            </ul>
            </nav>
        );
    }
}
 
export default NavBar;