import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './mainnavbar.css';
import { Link } from "react-router-dom";
import ContactMe from './home_page/contactme';
import './home_page/contactme.css';
import Pdf from './home_page/EduardoSosaResume.pdf'

class MainNavBar extends Component {
    render() { 
        return (
            <div className = 'sidebar'>
                <meta content="width=device-width, initial-scale=1" name="viewport" />
            <ul>
                <li className="item">
                <Link className="link" to="/">Home</Link>
                </li>
                <hr></hr>
                <li className="item">
                <Link className="link" to="/AboutMe">About Me</Link>
                </li>
                <hr></hr>
                <li className="item">
                <a className="link" href = {Pdf} target = "_blank" rel="noreferrer">CV</a>
                </li>
                <hr></hr>
                <li className="item">
                <Link className="link" to="/Portfolio">Portfolio</Link>
                </li>
                <hr></hr>
                <li className="item">
                <Link className="link" to="/Research">Research</Link>
                </li>
            </ul>

            <ContactMe className = "nav"/>
            </div>
        );
    }
}
 
export default MainNavBar;