import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import MainNavBar from '../mainnavbar'
import './portfolio.css'
import { Link } from "react-router-dom";


function Portfolio() {
  return (
    
    <React.Fragment> 
    <MainNavBar/>
    <div className = 'parent-portfolio'>
        <div className = 'child-portfolio'>
          <article className='portfolio'>
            <p className = 'section-portfolio'>Portfolio</p>
            <hr className = "title-line"></hr>
            <p className = 'project'>Colby Access</p>
            <p > While several navigation applications exist such as Open Street Maps,
            Google Maps, and Apple Maps, none of these take into account those with ambulatory
            disabilities. This project began with the motivation of building a navigation
            system that describes how accessible a path, entrance, or space is 
            for those that are wheel-chair bound or those that have difficulty moving through
            a space.</p>
            <p > This project was built using Leaflet and Open Street Maps. The front-end uses the 
              Angular frame-work. For the back-end we use Express.js and MongoDB. Though I implemented
              the current website application, I had two partners (William Johnson and Rebecca Isaacson) 
              aid me in collecting data, and implementing a rating system based off ADA guidelines. 
              Our current client is Colby College Accessability Services though a plan to potentially 
              expand this project beyond the realm of the college campus is being considered. 
            </p>
            <p>Below is a snapshot of the initial prototype. This project is ongoing. Currently 
              the user is able to flag a place for something that might have changed (such as a tree branch blocking 
              a path), and they're able to create an account with the website to make such flags. I am currently implementing 
              color gradients that allow for easier readability and the ability for an administrator to add data 
              from the front-end. 
            </p>
            <figure>
              <img id = 'ColbyAccess' src = {require('./gifs/ColbyAccess2.gif')} alt ="" />
              <figcaption className = "caption">Fig 1. Adding a flag onto a path using Colby Access</figcaption>
            </figure>
            <p className = 'project'>Multiple Hypothesis Tracking</p>
            <p>This past summer I was selected to participate in the very competitve Research 
              in Industrial Projects at UCLA. I was paired with the Aerospace Corporation based in El 
              Segundo, California. The Aerospace Corporation works with companies like NASA and SpaceX to 
              ensure that space launches and exploration missions are successful.
            </p>
            <p>As the Lower Earth Orbit becomes cluttered with space objects and satellites, the chances
              of a collision are at an all time high. Thus, the Aerospace Corporation wants to be able to track all objects in the lower 
              orbit to possibly avoid collisions between satellites and debris. One such solution is the Multi-Hypothesis Tracking 
              Algorithm which creates many possibilities about the possible trajectory of a set of objects, scores them, and 
              updates the current trajectory accordingly. 
            </p>
            <p>Our goal was two-fold. First, we conducted mathematical research to compare between other tracking algorithms 
              and MHT along with optimizing the way we score each of the hypotheses (more about that can be found in the research 
              section). The second goal was to implement a simulation for the MHT algorithm so that the Aerospace Corporation could visualize our 
              results and continue research on the MHT algorithm via the implementation of space aerodynamics. 
            </p>
            <p>Below I show this implementation which was coded in Python.</p>

            <figure>
              <img id = 'ColbyAccess' src = {require('./gifs/MHT.gif')} alt ="" />
              <figcaption className = "caption">Fig 2. Using the MHT Simulation to model 3 trajectories.</figcaption>
            </figure>

            <p className = 'project'>OS Schedulers</p>
            <p>
                As part of a course taught by professor Naser Al Madi, I was able to dive 
                into the world of Operating Systems. I have completed a series of projects where 
                I implement and compare several types of scheduling algorithms. These scheduling algorithms 
                focus on the goal of allocating processes to the CPU. I have implemented non-preemptive 
                algorithms such as Shortest-Job-First, FCFS, Priority Scheduling, and Lottery Scheduling. 
                I have also implemented preemptive scheduling algorithms such as Round-Robin, Shortest-Time-Remaining, 
                Preemptive Priority Scheduling, and Completely Fair Scheduling with the use of a Red-Black Tree.
            </p>
            <p>
              Finally, I was able to explore Linux's XV6 operating system and implement 
              several system calls along with some scheduling algorithms (lottery scheduling, round-robin priority scheduling,
              and priority scheduling). These projects can be found in my Github <a target = '_blank' href="https://github.com/EduardoSosaG/OSSchedulers">here.</a>

            </p>

            <p className = 'project'>Sushi Chef</p>
            <p>
                This project was the first time I worked with a team of other Computer 
                Science majors. Over the course of a month, we designed and built a video game 
                using PyGame. Our team included two artists (Hannah Gao and Cornelia Wang), Izzy Hurley, who was in 
                charge of sound design, Phuong Nguyen and Elliot Zhou who developed a multiplayer mode across 
                different computers, and Katie Andre and I who implemented the single-server game.

                In the game, two players compete to get a secret sushi formula. The players move around a map and 
                try to shoot at each other using ingredients. Whoever has the highest score at the end of 
                all levels wins. 
            </p>
            <figure>
              <img className = 'sushi' src = {require('./gifs/sushi1.gif')} alt ="" />
              <figcaption className = "caption">Fig 3. The tutorial in sushi chef.</figcaption>
            </figure>
            <figure>
              <img className = 'sushi' src = {require('./gifs/sushi2.gif')} alt ="" />
              <figcaption className = "caption">Fig 4. The first level of Sushi Chef.</figcaption>
            </figure>
            <figure>
              <img className = 'sushi' src = {require('./gifs/sushi4.gif')} alt ="" />
              <figcaption className = "caption">Fig 5. The final level of Sushi Chef where players battle in the dark.</figcaption>
            </figure>
            {/* <p className = 'project'>PCA Analysis</p>
              <p>
                
              </p> */}
          </article>
        </div>
    </div>
  </React.Fragment>
  );
}

export default Portfolio;