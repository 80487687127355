import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import Main from './main'
import './App.css';


function App() {
  return (
    
  <React.Fragment> 
  <div className="App" >
    <Main/>
  </div>
  </React.Fragment>
  );
}

export default App;
