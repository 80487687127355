import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import NavBar  from './navbar'
import ContactMe from './contactme'
import Banner from './banner'
import './home.css';


function Home() {
  return (
    
  <React.Fragment> 
  <div className="Home" >
    <img className = "img" src = {require('./jonathan-pease.jpg')} alt ="" />
    <NavBar />
    <ContactMe className = "home"/>
    <Banner />
  </div>
  </React.Fragment>
  );
}

export default Home;