import React from 'react';
// import styled, { keyframes } from 'styled-components';
import Typewriter from "typewriter-effect";
import 'bootstrap/dist/css/bootstrap.css';
import './banner.css';

// const animation = keyframes`
//     0% {opacity: 0}
//     100% {opacity: 100}
// `;

// const Wrapper = styled.span`
//     opacity: 0;
//     animation-name : ${animation};
//     animation-duration: 6s;
//     animation-fill-mode: forwards;
   
// `;

function Banner(){
    // return (<Wrapper className = 'banner'> Fuck you, Ari</Wrapper>)
    return (    <div className="banner">
    <Typewriter options={{delay:75, deleteSpeed:75}}

     onInit={(typewriter)=> {

     typewriter
      
     .typeString("Hello.")
       
     .pauseFor(1000)
     .deleteAll()
     .typeString("My name is Eduardo Sosa.")
     .pauseFor(1000)
     .typeString(" Welcome to my webpage.")
     .pauseFor(1000)
     .deleteAll()
     .pauseFor(1000)
     .typeString("I hope you enjoy.")
     .start();
     }
    }
     />
  </div>)
}

 
export default Banner;