import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import MainNavBar from '../mainnavbar'


function Research() {
  return (
    
  <React.Fragment> 
    <MainNavBar/>
    <div className = 'parent'>
        <div className = 'child'>
          <article className='aboutmetxt'>
            <p className = 'section'>Research</p>
            <hr className = "title-line"></hr>
            <p>
              This section is currently under construction.
            </p>
          </article>
        </div>
    </div>
  </React.Fragment>
  );
}

export default Research;